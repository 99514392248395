import React, { useState } from 'react';
import axios from 'axios';
import Modal from '../utils/Modal';
import { ReactComponent as FortWorth } from '../images/FortWorthBusiness.svg';
import marketWatch from '../images/marketwatch.jpg';
import estateInnovation from '../images/estateInnovation.png';
import top100 from '../images/top100.jpeg';
import entrepreneurship from '../images/entrepreneurship.jpg';
import Demo from '../images/demo.mp4';


function HeroHome() {

  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState({ loading: false, error: false, message: '' });

  const sendEmail = async (e) => {
    e.preventDefault();
    try{
      setStatus({loading: true, error: false, message: ''})

      const response = await
      axios
        .post('https://customer-portalv2-e9a1183ddf59.herokuapp.com/email-send', {
          email,
          message
        })
        const data = await response.data
          // Handle success.
          if(data.message) {
            setStatus({ ...status, message: response.data.message })
            setName('');
            setEmail('');
            setMessage('');

          } else {
            setStatus({ loading: false, error: false, message: ''})

          }
  } catch(e) {
    // setStatus({ loading: false, error: true, message: e.response })
  }
}

  return (
    <section className="relative">

      {/* Illustration behind hero content */}
      {/* <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div> */}

      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <h2 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">The leading AR/VR Platform for the

AEC and Building industry.</h2>
            <div className="max-w-3xl mx-auto">
              {/* <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Our landing page template works on all devices, so you only have to set it up once, and get beautiful results forever.</p> */}
              <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
                <div>
                  <input 
                  type="email" 
                  placeholder='email'
                  name='email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className="btn text-black bg-zuke-box w-full mb-4 sm:w-auto sm:mb-0"/>
                </div>
                <div>
                  <button onClick={sendEmail} className="btn text-white bg-zuke-red hover:bg-gray-800 w-full sm:w-auto sm:ml-4">JOIN OUR NEWSLETTER</button>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <div className="relative flex justify-center mb-8" data-aos="zoom-y-out" data-aos-delay="450">
              <div className="flex flex-col justify-center">
                <img className="mx-auto" src={require('../images/Zuke.jpg').default} width="768" height="432" alt="Zuke" />

              </div>
              <button className="absolute top-full flex items-center transform -translate-y-1/2 bg-white rounded-full font-medium group p-4 shadow-lg" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setVideoModalOpen(true); }} aria-controls="modal">
                <svg className="w-6 h-6 fill-current text-gray-400 group-hover:text-blue-600 flex-shrink-0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                  <path d="M10 17l6-5-6-5z" />
                </svg>
                <span className="ml-3">Watch the full video (1 min)</span>
              </button>
            </div>

            <Modal id="modal" ariaLabel="modal-headline" show={videoModalOpen} handleClose={() => setVideoModalOpen(false)}>
              <div className="relative pb-9/16">
                <iframe className="absolute w-full h-full" src={Demo} title="Video" allowFullScreen></iframe>
              </div>
            </Modal>

          </div> */}

        </div>

      </div>
{/* Items */}
<div className="max-w-sm md:max-w-4xl mx-auto grid gap-2 grid-cols-5 md:grid-cols-5">

{/* Item */}
<div className="flex items-center justify-center py-2 col-span-2 md:col-auto">
  <img src={marketWatch}/>
</div>

{/* Item */}
<div className="flex items-center justify-center py-2 col-span-2 md:col-auto">
<FortWorth/>
</div>

{/* Item */}
<div className="flex items-center justify-center py-2 col-span-2 md:col-auto">
  <img src={estateInnovation} />
</div>

{/* Item */}
<div className="flex items-center justify-center py-2 col-span-2 md:col-auto">
  <img src={entrepreneurship} />
</div>

{/* Item */}
<div className="flex items-center justify-center py-2 col-span-2 md:col-auto col-start-2 col-end-4">
  <img src={top100} />
</div>

</div>
    </section>
  );
}

export default HeroHome;