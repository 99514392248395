import React from 'react';

function Zuke() {
  return (
    <section className="relative">


      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-6">
            <div className='text-zuke-blue text-3xl text-left px-14 py-2 mx-4 md:mx-0'>
                Accessible
            </div>
              <div className="text-left px-12 py-4 mx-4 md:mx-0">
                <blockquote className="text-xl font-medium mb-4">
                Communicate clearly with our instant sharing capabilities. Your project can be as public or private as you wish and sharable through invite or QR code.
                </blockquote>
              </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-6">
            <div className='text-zuke-blue text-3xl text-right px-14 py-2 mx-4 md:mx-0'>
                Easy to Use
            </div>
              <div className="text-right px-12 py-4 mx-4 md:mx-0">
                <blockquote className="text-xl font-medium mb-4">
                Our AR/VR is made for the non-experts and experts alike. Our human-centered design approach makes your experience intuitive.
                </blockquote>
              </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-6">
            <div className='text-zuke-blue text-3xl text-left px-14 py-2 mx-4 md:mx-0'>
                Interactive
            </div>
              <div className="text-left px-12 py-4 mx-4 md:mx-0">
                <blockquote className="text-xl font-medium mb-4">
                Market your spaces in the most dynamic, interactive and forward-thinking path that allows anyone to ‘get it’ 
                </blockquote>
              </div>
        </div>
      </div>
    </section>
  );
}
export default Zuke;
