import React, { useState} from 'react';
import axios from 'axios';

function Newsletter() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState({ loading: false, error: false, message: '' });

  const sendEmail = async (e) => {
    e.preventDefault();
    try{
      setStatus({loading: true, error: false, message: ''})

      const response = await
      axios
        .post('https://customer-portalv2-e9a1183ddf59.herokuapp.com/email-send', {
          email,
          message
        })
        const data = await response.data
          // Handle success.
          if(data.message) {
            setStatus({ ...status, message: response.data.message })
            setName('');
            setEmail('');
            setMessage('');

          } else {
            setStatus({ loading: false, error: false, message: ''})
          }
  } catch(e) {
    // setStatus({ loading: false, error: true, message: e.response })
  }
}
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">

          {/* CTA box */}
          <div className="relative bg-zuke-box rounded py-10 md:py-16 shadow-2xl overflow-hidden" data-aos="zoom-y-out">
            <div className="relative flex flex-col lg:flex-row justify-center items-center">

              {/* CTA content */}
              <div className="text-center lg:text-left lg:max-w-xl">
                <h3 className="h3 text-black mb-2">Contact Us</h3>
                <p className="text-black-300 text-lg mb-6">Do yo have any questions about Twig Labs? send us a message</p>

                {/* CTA form */}
                <form className="w-full lg:w-auto">
                  <div className="flex flex-col sm:flex-row justify-center lg:mx-0">
                    <input 
                      name='E-mail'
                      value={email}
                      type='email'
                      placeholder='Email'
                      onChange={e => setEmail(e.target.value)} 
                    className="w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500" placeholder="Your email…" aria-label="Your email…" />
                  </div>
                  <div className="flex flex-col sm:flex-row justify-center lg:mx-0 pt-4">
                    <textarea
                      name='Message'
                      value={message}
                      type='text'
                      cols='40'
                      rows='5'
                      placeholder='Your message'
                      onChange={e => setMessage(e.target.value)} className="pr-6 w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500" aria-label="Your message..." />
                    <button onClick={sendEmail} className="btn text-white bg-zuke-red hover:bg-gray-800 shadow h-12 mt-12">Send Message</button>
                  </div>
                </form>
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>
    
  );
}

export default Newsletter;
